/**
 * Redux config to manage the app
 */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppSlices } from "../slices";
import { appManagementInitialState } from "../../models/slices";
import { SystemMessageConfig } from "../../contracts";
import { MessageType } from "../../enums";

/**
 * Application Management state layer, also named Redux Slice.
 */
export const appManagementSlice = createSlice({
	name: AppSlices.appManagement,
	initialState: appManagementInitialState,
	reducers: {
		setLoaderVisible: (state, action) => {
			state.loader.visible = action.payload;
		},
		setLoaderText: (state, action) => {
			state.loader.text = action.payload;
		},
		setAppMenuData: (state, action) => {
			state.menu.data = action.payload;
		},
		resetAppMenuData: (state) => {
			state.menu.data = appManagementInitialState.menu.data;
		},
		setLayout: (state, action) => {
			state.layout.layoutType = action.payload;
		},
		setBannerVisible: (state, action) => {
			state.banner.visible = action.payload;
		},
		setMessage: (state, action: PayloadAction<SystemMessageConfig>) => {
			state.message = action.payload;
		},
		setMessageText: (state, action: PayloadAction<string>) => {
			state.message.text = action.payload;
		},
		setMessageType: (state, action: PayloadAction<MessageType>) => {
			state.message.type = action.payload;
		},
		setMessageOpen: (state, action: PayloadAction<boolean>) => {
			state.message.open = action.payload;
		},
		setMessageIsHtml: (state, action: PayloadAction<boolean>) => {
			state.message.isHtml = action.payload;
		},
		setIsHighZoomLevel: (state, action: PayloadAction<boolean>) => {
			state.layout.isHighZoomLevel = action.payload;
		},
		setExpandedMenuItem: (state, action: PayloadAction<{ expanded: boolean; id: string }>) => {
			if (action.payload.expanded && !state.menu.expanded.includes(action.payload.id)) {
				state.menu.expanded = [...state.menu.expanded, action.payload.id];
			} else if (!action.payload.expanded) {
				state.menu.expanded = state.menu.expanded.filter((expandedItem: string) => {
					return expandedItem !== action.payload.id;
				});
			}
		},
		resetMenu: (state) => {
			state.menu.expanded = appManagementInitialState.menu.expanded;
		},
		setMenuItemSelected: (state, action: PayloadAction<string>) => {
			state.menu.selectedId = action.payload;
		},
		resetMenuItemSelected: (state, action: PayloadAction<string>) => {
			state.menu.selectedId = appManagementInitialState.menu.selectedId;
		},
		setMenuItemsSelected: (state, action: PayloadAction<string[]>) => {
			state.menu.selectedIds = action.payload;
		},
		addMenuItemsSelected: (state, action: PayloadAction<string[]>) => {
			action.payload.forEach((addingId) => {
				if (!state.menu.selectedIds.includes(addingId)) {
					state.menu.selectedIds = [...state.menu.selectedIds, addingId];
				}
			});
		},
		resetSubMenus: (state) => {
			state.menu.selectedIds = appManagementInitialState.menu.selectedIds;
		}
	}
});

export const {
	setLoaderVisible,
	setLoaderText,
	setAppMenuData,
	resetAppMenuData,
	setLayout,
	setBannerVisible,
	setMessage,
	setMessageText,
	setMessageType,
	setMessageOpen,
	setMessageIsHtml,
	setIsHighZoomLevel,
	setExpandedMenuItem,
	resetMenu,
	setMenuItemSelected,
	resetSubMenus,
	setMenuItemsSelected,
	addMenuItemsSelected,
	resetMenuItemSelected
} = appManagementSlice.actions;

export default appManagementSlice.reducer;
