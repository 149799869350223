import React, { useEffect, useState } from "react";
import { BookIcon } from "../common/icon";
import { NodeAPIServiceInstance } from "../../service/NodeAPI";
import { Link, useLocation, useParams } from "react-router-dom";
import { ApplicationRoutePaths } from "../router";
interface Props {
	className?: string;
}
/**
 * Sticky Nodes component to be used in the application Dashboards
 */
export const StickyNodes: React.FC<Props> = (props) => {
	const params = useParams();
	const location = useLocation();
	const [allStickyNodes, setAllStickyNodes] = useState([]);

	useEffect(() => {
		getStickyNodes();
	}, [location]);

	const getStickyNodes = async () => {
		const res = await NodeAPIServiceInstance.getStickyNodes(params.id);
		if (res) {
			setAllStickyNodes(res);
		}
	};

	return allStickyNodes.length > 0 ? (
		<div className={`dashboard-sticky-nodes-container ${props.className}`}>
			{allStickyNodes.length > 0 &&
				allStickyNodes.map((node) => {
					return (
						<h4 key={node.NodeId}>
							<BookIcon size="tiny" inverted title={"Article"} />
							<Link to={ApplicationRoutePaths.article(node.NodeId, node.Title)} title={node.Title}>
								{" "}
								<span>{node.Title}</span>
							</Link>
						</h4>
					);
				})}
		</div>
	) : null;
};
