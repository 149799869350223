/**
 * Enum to list different node types.
 */
export enum NodeType {
	Answer = "answer",
	Comment = "comment",
	Kbentry = "kbentry",
	Question = "question",
	Topic = "topic"
}

/**
 * Enum to identify the two comment types in a node.
 */
export enum CommentType {
	Answer = "answer",
	Comment = "comment"
}

/**
 * Enum to identify the different views in the edit/view topics
 */
export enum TagsModeType {
	Edit = "edit",
	View = "view"
}

/**
 * Enum to list different types of titles in a node.
 */
export enum TitleType {
	Event = "Event",
	Kbentry = "kbentry",
	Question = "question",
	Dashboard = "dashboard",
	QuestionDashboard = "questiondashboard",
	KbentryDashboard = "kbentrydashboard"
}

/**
 * Enum to identify spaces by id.
 */
export enum SpaceTypes {
	OnlineCourses = "36",
	SerpentSessions = "383"
}

/**
 * Enum to identify courses spaces by tags/topicsS.
 */
export enum SpaceTags {
	OnlineCourses = "36", // keep using old url
	SerpentSessions = "serpentsessions",
	CoreSecurityPrinciples = "coresecurityprinciples",
	ProtectTenantsAndIsolateProductionSystems = "protecttenantsandisolateproductionsystems",
	ProtectIdentitiesAndSecrets = "protectidentitiesandsecrets",
	ProtectNetworks = "protectnetworks",
	ProtectEngineeringSystems = "protectengineeringsystems",
	AccelerateResponseAndRemediation = "accelerateresponseandremediation"
}

/**
 * Enum to track Event Types
 */

export enum EventTypes {
	Virtual = "Virtual",
	InPerson = "InPerson",
	Mixed = "Mixed"
}

/**
 * Enum to log user Activity
 * Workflow types in a node
 */

export enum NodeActionTypes {
	NodeView = "nodeview",
	VoteDown = "voted down",
	VoteUp = "voted up",
	NewKbentry = "newkbentry", // Article
	Ask = "ask", // Question
	Comment = "comment", // Article main comment
	Answer = "answer", // Answer main comment
	AnswerToComment = "answer2comment", //reply to comment Article
	CommentToAnswer = "comment2answer", // reply to answer Question
	Action = "action",
	Accept = "accept",
	AddNodeAlias = "addNodeAlias",
	AddToGroup = "add-to-group",
	AskToAnswer = "asktoanswer",
	BonusRep = "bonusrep",
	CancelVoteDown = "cancelVoteDown",
	CancelVoteUp = "cancelVoteUp",
	Close = "close",
	Deactivate = "deactivate",
	Delete = "delete",
	UnDelete = "undelete",
	MakePrivate = "makePrivate",
	MakePublic = "makePublic",
	DeleteSpace = "delete-space",
	EditSpace = "edit-space",
	EditTopicDesc = "edit-topic-desc",
	EditTopicName = "edit-topic-name",
	ExpertAnswer = "expertanswer",
	Favorite = "favorite",
	Favorited = "favorited",
	Flag = "flag",
	Follow = "follow",
	Followed = "followed",
	GhostRevise = "ghostrevise",
	GiveRep = "giveRep",
	Lock = "lock",
	MakeSiteStickyAction = "MakeSiteStickyAction",
	MakeStickyAction = "MakeStickyAction",
	MoveToSpace = "moveToSpace",
	NewTopic = "newtopic",
	Nodeview = "nodeview",
	Organizational = "organizational",
	Outdated = "outdated",
	Publish = "publish",
	RedirectQuestion = "redirectQuestion",
	RedirectQuestionTo = "redirectQuestionTo",
	Reject = "reject",
	RemoveFromGroup = "removeFromGroup",
	ReOpen = "reopen",
	Reported = "reported",
	ReTag = "retag",
	Revise = "revise",
	RollBack = "rollback",
	SendToMod = "sendToMod",
	SmartSpace = "smart-space",
	Space = "space",
	Suspend = "suspend",
	SwitchPrivacy = "switch-privacy",
	TopicIcon = "topicIcon",
	TopicUsed = "topicUsed",
	Unaccept = "unaccept",
	Undeactivate = "undeactivate",
	Undelete = "undelete",
	UndoMakeSiteSticky = "undoMakeSiteSticky",
	UndoMakeSticky = "undoMakeSticky",
	Unfavorite = "unfavorite",
	UnflagIndex = "unflagIndex",
	Unfollow = "unfollow",
	Unsuspend = "unsuspend",
	Unwikify = "unwikify",
	UserEdit = "useredit",
	UserJoins = "userjoins",
	UserJoinSite = "userjoinsite",
	Viewed = "viewed",
	WikifyAction = "WikifyAction",
	AnskedToAnswer = "asked to answer",
	ShareWith = "share"
}

/**
 * Access to the keys provided by the Miscellaneous collection
 * endpoint.
 */
export enum MiscellaneousKeys {
	NodeIframeUrl = "Node_Iframe_Url"
}
