import React, { useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { ServiceHub } from "../../../service";
import { ScreenWidthSize, useScreenSize } from "../../../hooks";
import { useSelector } from "react-redux";
import { appManagementSelectors } from "../../../redux/selectors/appManagement";
import { Nav, INavLink } from "@fluentui/react";
import { useEffect } from "react";
import { isAbsoluteUrl } from "../../../lib/strikeLibrary";

/**
 * Renders the a Menu component, including its children, recursively,
 * and memoized.
 *
 * @param props
 * @returns
 */
function MenuComponentByType(): React.ReactElement {
	const filterSvc = useRef(ServiceHub.filterAPI.start());
	const appMenuSvc = useRef(ServiceHub.appMenuAPI.start());
	const isMobile = useScreenSize(ScreenWidthSize.Mobile);
	const NavMenuRef = useRef(null);
	const navigate = useNavigate();

	// TODO: Load the CheckAdminAccess result
	const hasEventAuthorization = useRef(false);

	const isHighZoomLevel = useSelector(appManagementSelectors.getIsHighZoomLevel);
	const menuSelectedItem = useSelector(appManagementSelectors.getMenuItemSelected);
	const transformedNavLinks = useSelector((state) =>
		appManagementSelectors.getTransformedNavLinks(state, hasEventAuthorization.current)
	);

	/**
	 * Reset all filter values entering a space via menu item click.
	 */
	const handleMenuItemClick = () => {
		filterSvc.current.resetTopicsFilters();
		filterSvc.current.setSelectedDashBoardButton("Dashboards");
		filterSvc.current.setDashboardNavigation(undefined);
	};

	/**
	 * Handle menu item click
	 */
	const onItemClick = useCallback(
		(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
			if (!item) {
				return;
			}

			if (!item.url && item.links && item.links.length > 0) {
				ev.preventDefault();
				appMenuSvc.current.setMenuItemSelected(item.key);
				appMenuSvc.current.setMenuItemsSelected(item.key);
				return;
			}

			if (item.url) {
				ev.preventDefault();
				appMenuSvc.current.setFocusOnParentElement(item.key);
				appMenuSvc.current.resetMenuItemSelected();
				appMenuSvc.current.resetSubMenus();
				appMenuSvc.current.resetMenu();
				if (!isAbsoluteUrl(item.url)) {
					navigate(item.url);
				} else {
					window.open(item.url, "_blank", "noopener,noreferrer");
				}

				handleMenuItemClick();
			}
		},
		[handleMenuItemClick]
	);

	/**
	 * Build custom html link only if its final clickable link
	 * @param link
	 * @returns
	 */
	const onRenderLink = (link: INavLink) => {
		const className = link.links ? "nav-submenu-item" : "nav-link-item";
		if (link.links && link.links.length > 0) {
			return (
				<span className={className} key={link.key} onClick={(ev) => onItemClick(ev, link)}>
					{link.name}
				</span>
			);
		} else {
			return (
				<span className={className} key={link.key} onClick={(ev) => onItemClick(ev, link)}>
					{link.name}
				</span>
			);
		}
	};

	const handleClickOutsideMenu = useCallback((event) => {
		if (NavMenuRef.current && !NavMenuRef.current.contains(event.target)) {
			appMenuSvc.current.resetAllMenu();
			appMenuSvc.current.resetSubMenus();
		}
	}, []);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideMenu);
		return () => {
			document.removeEventListener("mousedown", handleClickOutsideMenu);
		};
	}, []);

	// Temporary: Verifies the Event Authorization, which is restricting some SuperAdmin
	// Menu Items visibility.
	useEffect(() => {
		async function checkAdminAccess() {
			appMenuSvc.current.checkAccess().then((result) => {
				if (!result) {
					return;
				}

				hasEventAuthorization.current = Boolean(result);
			});
		}

		checkAdminAccess();
	}, []);

	const navStyles = {
		root: "custom-right-nav"
	};

	// In case this is a root point of the menu
	// if (props.Root) {
	if (!transformedNavLinks) return <></>;

	return isMobile || isHighZoomLevel ? (
		<div className="menu-mobile">
			<Nav groups={transformedNavLinks} onLinkClick={onItemClick} onRenderLink={onRenderLink} />
		</div>
	) : (
		<div ref={NavMenuRef} className="menu-desktop-container-wrapper">
			{transformedNavLinks.map((navRoot, index) => (
				<Nav
					key={index}
					groups={[navRoot]}
					onLinkClick={onItemClick}
					selectedKey={menuSelectedItem}
					onRenderLink={onRenderLink}
					styles={navStyles}
					focusZoneProps={{
						handleTabKey: 1
					}}
				/>
			))}
		</div>
	);
}

export { MenuComponentByType };
