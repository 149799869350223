import React from "react";
import { useNavigate } from "react-router";
import { FieldRequiredMark, FormActions, FormContainer, FormField } from "../../common/form";
import { DivEventHandler, InputTextEventHandler, RichTextInput, TextInput } from "../../common/text";
import { useCallback } from "react";
import { ApplicationRoutePaths } from "../../router";
import { DatePicker } from "../../common/datePicker/DatePicker";
import { TreeViewDropdown } from "../../common/dropdown";
import { useEventForm } from "../../../hooks/event/useEventForm";
import { useRef } from "react";
import { ServiceHub } from "../../../service";
import { useState } from "react";
import { SyntheticEvent } from "react";
import { IComboBox } from "@fluentui/react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { eventTypesValues } from "../../../redux/selectors/eventForm";
import { createSpaceInValue } from "../../../lib/strikeLibrary";
import { useSuperAdminAccessControl } from "../../../hooks/auth/useSuperAdminAccessControl";
import { UnauthorizedPage } from "../../common/page";
import { EventsShimmer } from "../../common/shimmer/events/shimmer.events";

/**
 * Contract for the input props of the NodeForm.
 */
export interface IFormEventFieldsProps {
	onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void> | void;
}

/**
 * Event main creation and edit form layout
 *
 * @returns React.FC
 */
export const FormEventFields: React.FC<IFormEventFieldsProps> = (props) => {
	const navigate = useNavigate();
	const {
		isNewEvent,
		eventFormData,
		bodySourceMode,
		virtualHTML,
		requireCapacity,
		eventTypes,
		canSubmit,
		getEventStartDate,
		getEventEndDate,
		getEventPublishDate,
		selectedEventTypeId,
		hasError
	} = useEventForm();
	const eventFormService = useRef(ServiceHub.eventForm.start());
	const submitDisabled = useMemo(() => !canSubmit, [canSubmit]);
	const eventManageAPI = useRef(ServiceHub.eventManageAPI.start());
	const params = useParams();
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [isFormLoading, setIsFormLoading] = useState(false);
	const [isFormLoaded, setIsFormLoaded] = useState(false);
	const { isSuperAdmin, requested, requesting } = useSuperAdminAccessControl();
	const today = new Date();
	const tomorrow = new Date();
	tomorrow.setDate(today.getDate() + 1);

	/**
	 * Generic method to set specific event form property value
	 */
	const onSetStateProp = useCallback(
		function (prop: string, value: any) {
			eventFormService.current.setData({
				...eventFormData,
				[prop]: value
			});
		},
		[eventFormData, eventFormService]
	);

	/**
	 * Managing for input change value
	 */
	const onChange = useCallback(
		(prop: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (typeof event?.preventDefault === "function") event.preventDefault();

			onSetStateProp(prop, typeof event === "string" ? event : event.currentTarget?.value);
		},
		[onSetStateProp]
	);

	const onChangeTime = useCallback(
		(prop: string) => (event: SyntheticEvent<IComboBox, Event>, data: Date) => {
			if (typeof event?.preventDefault === "function") event.preventDefault();
			onSetStateProp(prop, data ?? null);
		},
		[onSetStateProp]
	);

	/**
	 * Handle form discard button
	 * @param event
	 * @returns
	 */
	function onCancel(event: React.FormEvent<HTMLButtonElement>) {
		return navigate(ApplicationRoutePaths.dashboard());
	}

	/**
	 * Sync Event type with eventFormData
	 * @param item
	 */
	function handleSetEventType(item) {
		eventFormService.current.setData({
			...eventFormData,
			type: item.title
		});
	}

	/**
	 * Sets the Article Body writing mode,
	 * based on the a currentTarget.value
	 *
	 * @param event
	 */
	function onSetBodySourceMode(event: React.FormEvent<HTMLButtonElement>) {
		const value = event.currentTarget.value === "true";

		eventFormService.current.setBodySourceMode(value);
	}

	/**
	 * Handling RTE input text change
	 */
	const onChangeGeneric = useCallback(
		(prop: string) => (event: React.FocusEvent<HTMLElement>) => {
			onSetStateProp(prop, typeof event === "string" ? event : event.currentTarget.innerText);
			// When setting this state, clearing the errors to reprocess them
			virtualHTML.onClearErrors();
		},
		[onSetStateProp, virtualHTML.onClearErrors]
	);

	/**
	 * Handling RTE input html change
	 */
	const onChangeVirtualHtml = useCallback(
		(prop: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (typeof event?.preventDefault === "function") event.preventDefault();
			onSetStateProp(prop, typeof event === "string" ? event : event.currentTarget?.value);
			virtualHTML.onChange(typeof event === "string" ? event : event.currentTarget?.value);
		},
		[virtualHTML]
	);

	/**
	 * Convert coming existing Type value as string into
	 * a Dropdown selected value and send as selected
	 * @param itemType
	 */
	const setSelectedType = (itemType: string) => {
		// const typeToCompare = createSpaceInValue(itemType);
		const selectedItemValue = eventTypesValues.find((item) => item.Title === itemType);
		const convertedItemValue = {
			id: selectedItemValue.Id,
			title: selectedItemValue.Title
		};
		eventFormService.current.setSelectedEventTypeId(convertedItemValue.id);
	};

	/**
	 * Saving on state selected Date Start
	 * @param startDate
	 */
	const onSelectStartDate = (startDate: Date) => {
		eventFormService.current.setSelectedStartDate(startDate);
	};

	/**
	 * Saving on state selected End Date
	 * @param endDate
	 */
	const onSelectEndDate = (endDate: Date) => {
		eventFormService.current.setSelectedEndDate(endDate);
	};

	/**
	 * Saving on state selected publish date
	 * @param publishDate
	 */
	const onSelectPublishDate = (publishDate: Date) => {
		eventFormService.current.setSelectedPublishDate(publishDate);
	};

	/**
	 * Switching between create or edit based on location data
	 */
	useEffect(() => {
		if (location.pathname === ApplicationRoutePaths.eventCreate()) {
			eventFormService.current.resetData();
			setIsFormLoading(false);
			setIsFormLoaded(true);
		} else {
			if (params.id) {
				// eventFormService.current.setIsLoading(true);
				// eventFormService.current.setIsEventFormDataLoading(true);
				// eventFormService.current.setIsEventFormDataLoaded(false);
				setIsFormLoading(true);
				setIsFormLoaded(false);
				eventManageAPI.current
					.getEvent(params.id)
					.then((result: any) => {
						if (result instanceof Error) {
							ServiceHub.message.error(result.message);
							throw result;
						}

						eventFormService.current.setData({
							...eventFormData,
							id: result.Id,
							title: result.Title,
							type: result.Type,
							venue: result.Venue,
							dateStart: result.DateStart,
							dateEnd: result.DateEnd,
							pointOfContact: result.PointOfContact,
							presentedBy: result.PresentedBy,
							food: result.Food,
							publishSchedule: result.PublishSchedule,
							description: result.Description,
							inPersonCapacity: result.InPersonCapacity
						});

						setSelectedType(result.Type);
						onSelectStartDate(result.DateStart);
						onSelectEndDate(result.DateEnd);
						onSelectPublishDate(result.PublishSchedule);
					})
					.finally(() => {
						setIsFormLoading(false);
						setIsFormLoaded(true);
						// eventFormService.current.setIsEventFormLoading(false);
						// eventFormService.current.setIsEventFormDataLoading(false);
						// eventFormService.current.setIsEventFormDataLoaded(true);
					});
			}
		}

		window.scrollTo(0, 0);
	}, [params.id, location, eventFormService.current]);

	const isTheSameDate = useMemo((): boolean => {
		if (getEventEndDate && getEventStartDate) {
			let eventEndDate = new Date(getEventEndDate);
			let eventStartDate = new Date(getEventStartDate);
			return (
				eventEndDate.getFullYear() === eventStartDate.getFullYear() &&
				eventEndDate.getMonth() === eventStartDate.getMonth() &&
				eventEndDate.getDate() === eventStartDate.getDate()
			);
		}
		return false;
	}, [getEventStartDate, getEventEndDate]);

	const resolvedTimeRange = useMemo(() => {
		if (isTheSameDate && getEventStartDate) {
			let startDate = new Date(getEventStartDate);
			let eventStartDateHours = startDate.getHours() + 1;
			let timeRange = { start: eventStartDateHours, end: 24 };
			return timeRange;
		}
		return null;
	}, [getEventStartDate, getEventEndDate, isTheSameDate]);

	/**
	 * Loading temp component to show shimmer while list its loading
	 * @returns
	 */
	const renderLoading = () => {
		return (
			<div className={`row`}>
				<div className="col-md-12 col-lg-12 col-xl-12" tabIndex={0} aria-label="Event Form Page">
					<div className="space-manager-container">
						<EventsShimmer size={2} rows={8} formColumns />
					</div>
				</div>
			</div>
		);
	};

	/**
	 * Shows unauthorized component
	 * @returns
	 */
	const renderUnauthorized = () => {
		return <UnauthorizedPage />;
	};

	if (!requested && (requesting || isFormLoading)) {
		return renderLoading();
	}

	if (isSuperAdmin)
		return (
			<div className={`row`}>
				<div className="col-md-12 col-lg-6 col-xl-12 event-form-page" tabIndex={0} aria-label="Event Form Page">
					{isFormLoading ? null : (
						<FormContainer
							onSubmit={props?.onSubmit ?? undefined}
							title={isNewEvent ? "Create Event" : "Edit Event"}
						>
							<div className={"row"}>
								<div className="col-md-12 col-lg-6 col-xl-6">
									<FormField id="Title" label="Title" required>
										<TextInput
											id="Title"
											placeholder={"Enter Event Name or Title"}
											onChange={onChange("title")}
											value={eventFormData.title}
											required
											maxLength={200}
										/>
									</FormField>
								</div>
								<div className="col-md-12 col-lg-6 col-xl-6">
									<FormField id="EventType" label="Event Type" required>
										<TreeViewDropdown
											id="EventType"
											options={eventTypes}
											onChange={handleSetEventType}
											placeholder={"Select Event Type"}
											selectedItem={selectedEventTypeId}
											ariaRequired
										/>
									</FormField>
								</div>
							</div>
							{requireCapacity ? (
								<div className={"row"}>
									<div className="col-md-12 col-lg-6 col-xl-6">
										<FormField id="EventMaxCapacity" label="Event Max Capacity Number" required>
											<TextInput
												id="EventMaxCapacity"
												placeholder={"Set event max attendees capacity number"}
												onChange={onChange("inPersonCapacity")}
												value={eventFormData.inPersonCapacity}
												required
												maxLength={200}
											/>
										</FormField>
									</div>
								</div>
							) : null}

							<div className={"row"}>
								<div className="col-md-12 col-lg-6 col-xl-6">
									<FormField id="StartDate" label="Start Date and Time" required>
										<DatePicker
											id="StartDate"
											label="Event Start"
											onChange={onChangeTime("dateStart")}
											onSelectDate={onSelectStartDate}
											selectedDate={getEventStartDate}
											useTime
											minDate={today}
											className="event-create-form-datepicker-start"
										/>
									</FormField>
								</div>
								<div className="col-md-12 col-lg-6 col-xl-6">
									<FormField id="EndDate" label="End Date and Time" required>
										<DatePicker
											id="EndDate"
											label="Event End"
											onChange={onChangeTime("dateEnd")}
											onSelectDate={onSelectEndDate}
											selectedDate={getEventEndDate}
											useTime
											minDate={getEventStartDate}
											className="event-create-form-datepicker-end"
											timeRange={resolvedTimeRange}
										/>
									</FormField>
								</div>
							</div>

							<div className={"row"}>
								<div className="col-md-12 col-lg-6 col-xl-6">
									<FormField id="PresentedBy" label="Presented by">
										<TextInput
											id="PresentedBy"
											placeholder={"Please enter name of Presenter"}
											onChange={onChange("presentedBy")}
											value={eventFormData.presentedBy}
											maxLength={200}
										/>
									</FormField>
								</div>

								<div className="col-md-12 col-lg-6 col-xl-6">
									<FormField id="Venue" label="Venue" required>
										<TextInput
											id="Venue"
											placeholder={"Please enter venue information"}
											onChange={onChange("venue")}
											value={eventFormData.venue}
											required
											maxLength={200}
										/>
									</FormField>
								</div>
							</div>

							<div className={"row"}>
								<div className="col-md-12 col-lg-6 col-xl-6">
									<FormField id="SchedulePublish" label="Schedule Publish">
										<DatePicker
											id="SchedulePublish"
											label="Event Publish"
											onChange={onChangeTime("publishSchedule")}
											onSelectDate={onSelectPublishDate}
											selectedDate={getEventPublishDate}
											useTime
											minDate={tomorrow}
											className="event-create-form-datepicker-publish"
										/>
									</FormField>
								</div>

								<div className="col-md-12 col-lg-6 col-xl-6">
									<FormField id="PointOfContact" label="Point of Contact">
										<TextInput
											id="PointOfContact"
											placeholder={"Enter Point of Contact"}
											onChange={onChange("pointOfContact")}
											value={eventFormData.pointOfContact}
											maxLength={200}
										/>
									</FormField>
								</div>
							</div>

							<div className={"row"}>
								<div className="col-md-12 col-lg-6 col-xl-6">
									<FormField id="Food" label="Food">
										<TextInput
											id="Food"
											placeholder={"Enter Food details"}
											onChange={onChange("food")}
											value={eventFormData.food}
											maxLength={200}
										/>
									</FormField>
								</div>

								<div className="col-md-12 col-lg-6 col-xl-6"></div>
							</div>

							<FormField id="Description" required>
								<div className="form-field-header">
									<label htmlFor="Description" aria-label="Description">
										Description <FieldRequiredMark />
									</label>
									<div className="button-group">
										<button
											type="button"
											onClick={onSetBodySourceMode}
											value={"false"}
											className={`${!bodySourceMode ? "selected" : ""}`}
										>
											Preview
										</button>
										<button
											type="button"
											onClick={onSetBodySourceMode}
											value={"true"}
											className={`${bodySourceMode ? "selected" : ""}`}
										>
											Source
										</button>
									</div>
								</div>
								<div className="form-control">
									<RichTextInput
										id="Description"
										placeholder={
											bodySourceMode
												? "Write your event details description HTML here..."
												: "Write your event description content here..."
										}
										onChange={
											bodySourceMode
												? (onChangeGeneric("description") as DivEventHandler)
												: (onChangeVirtualHtml("description") as InputTextEventHandler)
										}
										value={!bodySourceMode ? virtualHTML.virtualHtml : virtualHTML.originalHtml}
										sourceMode={bodySourceMode}
										// error={Description === "" && hasError}
										onBlur={(event) =>
											virtualHTML.onSyncOriginal(
												event.currentTarget.innerHTML,
												(newContent: string) => {
													onSetStateProp("description", newContent);
												}
											)
										}
									/>
								</div>
							</FormField>
							<FormActions
								actions={[
									{
										type: "submit",
										text: isNewEvent ? "Save" : "Update",
										disabled: submitDisabled
									},
									{
										type: "button",
										variation: "secondary",
										className: "cancel",
										text: "Discard",
										onClick: onCancel
									}
								]}
							/>
						</FormContainer>
					)}
				</div>
			</div>
		);

	if (!isSuperAdmin && isFormLoaded && !isFormLoading) {
		return renderUnauthorized();
	}
};
