import React, { useEffect } from "react";
import { Divider, Segment } from "semantic-ui-react";
import { Icon } from "semantic-ui-react";
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from "@fluentui/react/lib/DetailsList";
import { SearchBox } from "@fluentui/react/lib/SearchBox";
import { useState } from "react";
import { IEventRegisteredUsers } from "../../../contracts/models";
import { ServiceHub } from "../../../service";
import { useRef } from "react";
import { useMemo } from "react";
import { getQueryUserToEventCheckin, utcToLocalDateString } from "../../../lib/strikeLibrary";
import { TreeViewDropdown } from "../../common/dropdown";
import { useEventForm } from "../../../hooks";
import { IconButton } from "../../common/button";

/**
 * Controls the props of Event Check-In component
 */
interface IEventCheckInDetailsProps {}

/**
 * Event Check-In component to allow user to search
 * through the registered users and confirm check-in.
 *
 * @param props IShowEventCheckInProps
 * @returns React.FunctionComponent<IShowEventProps>
 */
export const EventCheckInDetails: React.FunctionComponent<IEventCheckInDetailsProps> = (props) => {
	const eventManageAPI = useRef(ServiceHub.eventManageAPI.start());
	const eventFormService = useRef(ServiceHub.eventForm.start());
	const { eventSelectList, eventSelectedItem, eventSelectedId } = useEventForm();

	const [attendees, setAttendees] = useState<IEventRegisteredUsers[]>([]);
	const [filterText, setFilterText] = useState<string>("");
	const [filterOption, setFilterOption] = useState<string>("");
	const CountsOptionStatsRef = useRef(null);

	const columns: IColumn[] = [
		{ key: "column1", name: "Name", fieldName: "Name", minWidth: 150, maxWidth: 150, isResizable: true },
		{ key: "column2", name: "Email", fieldName: "Email", minWidth: 170, maxWidth: 170, isResizable: true },
		{ key: "column3", name: "RSVP Type", fieldName: "RSVPType", minWidth: 70, maxWidth: 70, isResizable: true },
		{
			key: "column4",
			name: "Reg On",
			fieldName: "",
			minWidth: 100,
			maxWidth: 100,
			isResizable: true,
			onRender: (item) => {
				return <span>{utcToLocalDateString(item.RegistrationDate)}</span>;
			}
		},
		{
			key: "column5",
			name: "Updated On",
			fieldName: "",
			minWidth: 100,
			maxWidth: 100,
			isResizable: true,
			onRender: (item) => {
				return <span>{utcToLocalDateString(item.RegistrationUpdateDate)}</span>;
			}
		},
		{
			key: "column6",
			name: "Confirmed?",
			fieldName: "",
			minWidth: 80,
			maxWidth: 80,
			isResizable: true,
			onRender: (item) => {
				if (item.Confirmed) {
					return <span className="event-registration-label-confirmed">Yes</span>;
				} else {
					return <span className="event-registration-label-waiting">No</span>;
				}
			}
		},
		{
			key: "column7",
			name: "Checked-In?",
			fieldName: "",
			minWidth: 80,
			maxWidth: 80,
			isResizable: true,
			onRender: (item) => {
				if (item.CheckedIn) {
					return <span className="event-registration-label-checkedin">Yes</span>;
				} else {
					return <span className="event-registration-label-pending">No</span>;
				}
			}
		},
		{
			key: "column8",
			name: "",
			fieldName: "",
			minWidth: 80,
			maxWidth: 80,
			isResizable: true,
			onRender: (item) => {
				if (item.CheckedIn) {
					return (
						<div className="event-checkin-list-actions">
							<p>Checked-In</p>
						</div>
					);
				} else {
					return (
						<div className="event-checkin-list-actions">
							<IconButton
								iconName="SkypeCircleCheck"
								ariaLabel="Check-In User"
								onClick={() => handleUserCheckIn(item.Id, item.Email)}
								title="Check-In User"
								text="Check-In"
								className="event-registration-checkin-button"
								disabled={item.CheckedIn}
							/>
						</div>
					);
				}
			}
		}
	];

	/**
	 * Handling user search via input
	 * @param event
	 * @param newValue
	 */
	const handleSearch = (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
		setFilterText(newValue);
	};

	/**
	 * Updating list of Attendees after search
	 */
	const filteredRegisteredUserList = useMemo(() => {
		let filtered = attendees.filter(
			(user) =>
				user.Name.toLowerCase().includes(filterText?.toLowerCase() || "") ||
				user.Email.toLowerCase().includes(filterText?.toLowerCase() || "")
		);

		if (filterOption === "confirmed") {
			filtered = filtered.filter((user) => user.Confirmed);
		}
		if (filterOption === "waiting") {
			filtered = filtered.filter((user) => !user.Confirmed);
		}
		if (filterOption === "checkedin") {
			filtered = filtered.filter((user) => user.CheckedIn);
		}
		if (filterOption === "virtual") {
			filtered = filtered.filter((user) => user.RSVPType === "Virtual");
		}
		if (filterOption === "inperson") {
			filtered = filtered.filter((user) => user.RSVPType === "In Person");
		}
		return filtered;
	}, [attendees, filterText, filterOption]);

	/**
	 * Get global total number of confirmed users
	 * looking into the first user if any available
	 */
	const totalConfirmed = useMemo(() => {
		if (attendees.length > 0) {
			return attendees[0].ConfirmedRegistrationCount;
		}
		return 0;
	}, [attendees]);

	/**
	 * Get global total number of checked-in users
	 * looking into the first user if any available
	 */
	const totalCheckedIn = useMemo(() => {
		if (attendees.length > 0) {
			return attendees[0].CheckedInCount;
		}
		return 0;
	}, [attendees]);

	/**
	 * Get global total number of waiting users
	 * based on the first user if any available
	 */
	const totalWaiting = useMemo(() => {
		if (attendees.length > 0) {
			return attendees[0].WaitingRegistrationCount;
		}
		return 0;
	}, [attendees]);

	/**
	 * Get global total number of In Person assisting users
	 * based on the first user if any available
	 */
	const totalInPerson = useMemo(() => {
		if (attendees.length > 0) {
			return attendees[0].InPersonCount;
		}
		return 0;
	}, [attendees]);

	/**
	 * Get global total number of In Person assisting users
	 * based on the first user if any available
	 */
	const totalVirtual = useMemo(() => {
		if (attendees.length > 0) {
			return attendees[0].VirtualCount;
		}
		return 0;
	}, [attendees]);

	/**
	 * Checking In user from the Register users list via 'Check-In' button
	 * @param userId
	 * @param userEmail
	 */
	const handleUserCheckIn = (userId: number, userEmail: string) => {
		if (userId && userEmail && eventSelectedId) {
			let userToCheckInQuery = getQueryUserToEventCheckin(userId, userEmail);

			eventManageAPI.current.setEventAttendeeCheckin(userToCheckInQuery).then((result) => {
				if (result) {
					eventManageAPI.current.getEventAttendeesList(eventSelectedId).then((refreshedAttendees) => {
						if (refreshedAttendees) {
							setAttendees(refreshedAttendees);
						}
					});
				}
			});
		}
	};

	useEffect(() => {
		eventFormService.current.setIsEventListLoading(true);
		eventFormService.current.setIsEventListLoaded(false);
		async function loadEventsList() {
			eventManageAPI.current
				.getEvents()
				.then(async (items) => {
					eventFormService.current.setEventsList(items);
				})
				.finally(() => {
					eventFormService.current.setIsEventListLoading(false);
					eventFormService.current.setIsEventListLoaded(true);
				});
		}
		loadEventsList();
	}, []);

	/**
	 * Get Registered users for that specific Event Selected
	 * @param item
	 */
	function handleEventSelect(item) {
		if (item) {
			eventManageAPI.current.getEventAttendeesList(item.id).then((result) => {
				if (result) {
					setAttendees(result);
				}
			});
		}
	}

	/**
	 * handle filter by option from value passed from item click
	 * @param option
	 */
	const handleFilterByOption = (event: React.MouseEvent<HTMLButtonElement>, option: string) => {
		if (event.currentTarget.classList.contains("selected")) {
			setFilterOption("");
		} else {
			setFilterOption(option);
		}
	};

	return (
		<React.Fragment>
			<Segment basic className="strike-node-wrapper">
				<div className="event-checkin-header-container">
					<div className="event-checkin-header-title">
						<div className="event-checkin-header-icon">
							<Icon
								name="calendar alternate outline"
								size="big"
								tabIndex={0}
								aria-label="Event Icon"
								role="img"
							/>
						</div>
						<div className="event-checkin-header-select">
							<TreeViewDropdown
								id="EventSelect"
								options={eventSelectList}
								onChange={handleEventSelect}
								placeholder={"Select Event Name"}
								selectedItem={eventSelectedItem}
							/>
						</div>
					</div>
				</div>
				<Divider />
				{eventSelectedItem ? (
					<>
						<div className="event-checkin-counts-container" ref={CountsOptionStatsRef}>
							<div
								className="event-checkin-counts-card"
								tabIndex={0}
								aria-label={`${
									filterOption === "confirmed"
										? "Option Selected Total Confirmed Counts"
										: "Total Confirmed Counts"
								}`}
							>
								<button
									className={`event-checkin-counts-actions ${
										filterOption === "confirmed" ? "selected" : ""
									}`}
									onClick={(event) => handleFilterByOption(event, "confirmed")}
								>
									Confirm Reg: <strong>{totalConfirmed}</strong>
								</button>
							</div>
							<div
								className="event-checkin-counts-card"
								tabIndex={0}
								aria-label={`${
									filterOption === "waiting"
										? "Option Selected Total Waiting Counts"
										: "Total Waiting Counts"
								}`}
							>
								<button
									className={`event-checkin-counts-actions ${
										filterOption === "waiting" ? "selected" : ""
									}`}
									onClick={(event) => handleFilterByOption(event, "waiting")}
								>
									Waiting Reg: <strong>{totalWaiting}</strong>
								</button>
							</div>
							<div
								className="event-checkin-counts-card"
								tabIndex={0}
								aria-label={`${
									filterOption === "checkedin"
										? "Option Selected Total Checked-In Counts"
										: "Total Checked-In Counts"
								}`}
							>
								<button
									className={`event-checkin-counts-actions ${
										filterOption === "checkedin" ? "selected" : ""
									}`}
									onClick={(event) => handleFilterByOption(event, "checkedin")}
								>
									Checked-In: <strong>{totalCheckedIn}</strong>
								</button>
							</div>
							<div
								className="event-checkin-counts-card"
								tabIndex={0}
								aria-label={`${
									filterOption === "inperson"
										? "Option Selected Total In Person Attendees"
										: "Total In Person Attendees"
								}`}
							>
								<button
									className={`event-checkin-counts-actions ${
										filterOption === "inperson" ? "selected" : ""
									}`}
									onClick={(event) => handleFilterByOption(event, "inperson")}
								>
									In Person: <strong>{totalInPerson}</strong>
								</button>
							</div>
							<div
								className="event-checkin-counts-card"
								tabIndex={0}
								aria-label={`${
									filterOption === "virtual"
										? "Option Selected Total Virtual Attendees"
										: "Total Virtual Attendees"
								}`}
							>
								<button
									className={`event-checkin-counts-actions ${
										filterOption === "virtual" ? "selected" : ""
									}`}
									onClick={(event) => handleFilterByOption(event, "virtual")}
								>
									Virtual: <strong>{totalVirtual}</strong>
								</button>
							</div>
						</div>
						<Divider />
					</>
				) : null}

				<div className="event-checkin">
					{eventSelectedItem ? (
						<>
							<SearchBox
								className="event-checkin-search-input"
								placeholder="Search Person by name or email"
								onChange={handleSearch}
								autoComplete="off"
							/>
							<div className="event-checkin-list-container">
								<DetailsList
									items={filteredRegisteredUserList}
									columns={columns}
									setKey="set"
									layoutMode={DetailsListLayoutMode.justified}
									selectionPreservedOnEmptyClick={false}
									selectionMode={SelectionMode.none}
									ariaLabelForSelectionColumn="Toggle selection"
									checkButtonAriaLabel="select row"
									className="event-checkin-list-container-list"
									focusZoneProps={{
										allowTabKey: true
									}}
								/>
							</div>
						</>
					) : (
						<p tabIndex={0} aria-label="No Records Found. Please select the Event.">
							No Records Found. Please select the Event.
						</p>
					)}
				</div>
			</Segment>
		</React.Fragment>
	);
};
