import React, { useCallback, useMemo } from "react";
import { DatePicker as FluentDatePicker, TimePicker, IComboBox, ITimeRange } from "@fluentui/react";
import { utcToLocalDateTimeString } from "../../../lib/strikeLibrary";

/**
 * Controls the input properties of TextInput
 */
export interface IDatePickerProps {
	id?: undefined | string;
	useTime?: undefined | boolean;
	label: string;
	onChange?: (event: React.FormEvent<IComboBox>, time: Date | undefined) => void;
	onSelectDate?: (time: Date) => void;
	selectedDate: Date;
	minDate?: undefined | Date;
	maxDate?: undefined | Date;
	className?: string;
	timeRange?: ITimeRange;
}

/**
 * Strike Date Picker custom component Build up.
 * Support Time Picking as well.
 */
export const DatePicker: React.FC<IDatePickerProps> = (props) => {
	/**
	 * Handle selected time and sync data with form (date and time)
	 * @param event
	 * @param time
	 */
	const handleChangeTime = (event: React.FormEvent<IComboBox>, time: Date | undefined) => {
		props.onChange(event, time);
		if (props.onSelectDate) {
			props.onSelectDate(time);
		}
	};

	/**
	 * Handle selected date from Date Picker
	 */
	const onSelectDate = useCallback(
		(_selectedDate: Date) => {
			props.onSelectDate(_selectedDate);
		},
		[props.onSelectDate]
	);

	return (
		<div>
			<div className="date-picker-time-grid">
				<FluentDatePicker
					id={props.id}
					placeholder={`Select ${props.label} date`}
					value={props?.selectedDate ? new Date(utcToLocalDateTimeString(props.selectedDate)) : null}
					onSelectDate={onSelectDate}
					ariaLabel={`Select ${props.label} date`}
					minDate={props?.minDate ? new Date(props.minDate) : undefined}
					className={props.className}
				/>
				{props?.useTime ? (
					<TimePicker
						placeholder={`Select ${props.label} time`}
						dateAnchor={props?.selectedDate ? new Date(utcToLocalDateTimeString(props.selectedDate)) : null}
						value={props?.selectedDate ? new Date(utcToLocalDateTimeString(props.selectedDate)) : null}
						onChange={handleChangeTime}
						ariaLabel={`Select ${props.label} time`}
						increments={15}
						className={`${props.className}-timepicker`}
						timeRange={props?.timeRange ? props.timeRange : undefined}
					/>
				) : null}
			</div>
		</div>
	);
};
