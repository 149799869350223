import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Divider } from "semantic-ui-react";
import PageHeader from "../../Header/page.header";
import { Alert } from "../../common/alert";
import { NodeSummary } from "./../node.summary";
import { useFilterSlice } from "../../../hooks/filter/useFilterSlice";
import { setPageIndex } from "../../../redux/reducers/filter";
import { ServiceHub } from "../../../service";
import { NodeType, TitleType } from "../../../contracts/models/strikeEnums";
import { LightPagination } from "../../pagination";
import { useRef } from "react";
import { StickyNodes } from "../../stickyNodes/stickyNodes";

/**
 * The Node List Page component.
 * Used to render a list of Nodes loaded from the Backend services.
 *
 * @returns React.FC
 */
export const ArticlesNodesList: React.FC = () => {
	const params = useParams();
	const filterData = useFilterSlice();
	const searchTopic = filterData.searchTopic;
	const searchTitleValue = filterData.searchTitle;
	const searchTopics = filterData.searchTopics;
	const strikeNodes = filterData.nodeList;
	const [refreshPaginationKey, setRefreshPagination] = useState(1);
	const isLoading = filterData.isNodesLoading;
	const filterAPI = useRef(ServiceHub.filterAPI.start());

	const onPageChange = (pageIndex: number): void => {
		setPageIndex(pageIndex);
		let queryModel = ServiceHub.nodeAPI.getQueryNodeObject(
			Number(params.id),
			pageIndex,
			searchTitleValue,
			searchTopics,
			searchTopic,
			NodeType.Kbentry
		);
		filterData.getNodesList(queryModel);
	};

	useEffect(() => {
		filterAPI.current.setSelectedDashBoardButton("Articles");
		filterAPI.current.resetTopicsFilters();
		filterAPI.current.setDashboardNavigation(NodeType.Kbentry);

		let queryModel = ServiceHub.nodeAPI.getQueryNodeObject(
			Number(params.id),
			1,
			"",
			"",
			searchTopic,
			NodeType.Kbentry
		);
		filterData.getNodesList(queryModel);
		refreshPagination();
		filterAPI.current.setDashboardLoading();
	}, []);

	const refreshPagination = () => {
		setRefreshPagination(Math.random());
	};

	return (
		<>
			<PageHeader Title="All Articles" Type={TitleType.KbentryDashboard} showActions />
			<StickyNodes />
			<Divider />
			{!isLoading && strikeNodes.nodes.length === 0 ? (
				<Alert text={`No Records Found.`} />
			) : (
				<div>
					<div className="row layout-list">
						{strikeNodes.nodes.map((node, nodeIndex) => (
							<NodeSummary key={nodeIndex} Index={nodeIndex} NodeInfo={node} />
						))}
					</div>
					<LightPagination
						key={refreshPaginationKey}
						totalPages={strikeNodes.totalRecordCount}
						onPageChange={onPageChange}
					/>
				</div>
			)}
		</>
	);
};
